import JustValidate from "just-validate";
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2'

const tabs = document.querySelector('.tabs')

if (tabs) {
  tabs.addEventListener('click', e => {
    const tab = e.target.closest('div.tab')
    const currentTabClosed = tab && !tab.classList.contains('tab--active')

    if (currentTabClosed) {
      const activeTab = tabs.querySelector('.tab--active')
      activeTab?.classList.remove('tab--active')
      tab.classList.add('tab--active')
    }
  })
}

const form = document.querySelector('#contact-form')

emailjs.init('user_squGfjoBFtyviUqg9atKc')
const validation = new JustValidate(form, {lockForm: true})

const blockForm = () => {
  const inputs = form.querySelectorAll('.form__input')
  const btn = form.querySelector('button')
  inputs.forEach(i => i.disabled = true)
  btn.disabled = true
}

const unblockForm = () => {
  const inputs = form.querySelectorAll('.form__input')
  const btn = form.querySelector('button')
  inputs.forEach(i => i.disabled = false)
  btn.disabled = false
}

const onValidationSuccess = () => {
  blockForm();

  const serviceID = 'default_service';
  const templateID = 'template_xi0t67g';

  emailjs.sendForm(serviceID, templateID, form)
    .then(() => {
      Swal.fire({
        title: 'Success',
        text: 'Your message successfully sent',
        position: 'bottom-end',
        showConfirmButton: true,
        customClass: {confirmButton: 'btn'},
        buttonsStyling: false
      }).then(() => {
        form.reset()
      })
    })
    .catch((err) => {
      console.error(JSON.stringify(err));
    })
    .finally(() => {
      unblockForm()
    })
}

validation
  .addField('#form-name', [{rule: 'required', message: 'Name is required'}])
  .addField('#form-email', [{rule: 'required', message: 'Email is required'}, {
    rule: 'email',
    message: 'Email is invalid'
  }])
  .addField('#form-message', [{rule: 'maxLength', value: 500}])
  .onSuccess(e => {
    e.preventDefault()
    onValidationSuccess()
  })

